import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader/Loader'
import CustomTitle from '../../components/UI/title/CustomTitle'
import './style.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import gsap from 'gsap';
import { ReactComponent as IconAi } from '../../assets/icons/ai.svg';
import { ReactComponent as IconYes } from '../../assets/icons/yes.svg';
import { ReactComponent as IconNo } from '../../assets/icons/no.svg';

import AiAnalyzerImg from './components/AiAnalyzerImg/AiAnalyzerImg';
import axios from 'axios';
import { useFetchPicturesQuery } from '../../api/unsplashApi';
import AIAnalyzerContent from './AIAnalyzerContent';
import { a } from 'react-spring';
import useMixpanel from '../../hooks/useMixpanel';
import { useCheckSubscriptionQuery, useGetInfoQuery } from '../../api/dataApi';


export default function AIAnalyzer({ showIndustry, setWhiteWrapper, setWelcometo }) {

    useEffect(() => {
        setWhiteWrapper(true)
        setWelcometo(false)
    }, [])

    const history = useHistory();
    const [progress, setProgress] = useState(0);
    const [title, setTitle] = useState('Analyzing your industry trends...');
    const [secondLoader, setSecondLoader] = useState(false);
    const [thirdLoader, setThirdLoader] = useState(false);

    const [isFirstPopupShow, setIsFirstPopupShow] = useState(false);
    const [isSecondPopupShow, setIsSecondPopupShow] = useState(false);
    const [isThirdPopupShow, setIsThirdPopupShow] = useState(false);



    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev < 33) return prev + 1;
                if (secondLoader && prev < 66) return prev + 1;
                if (thirdLoader && prev < 100) return prev + 1;
                clearInterval(interval);
                return prev;
            });
        }, 121);

        return () => clearInterval(interval);
    }, [secondLoader, thirdLoader]);

    useEffect(() => {
        if (progress === 33) {
            setIsFirstPopupShow(true)
        }
        if (progress === 66) {
            setIsSecondPopupShow(true)
        }
        if (progress === 100) {
            setIsThirdPopupShow(true)
        }
    }, [progress])

    const handleClickChoose = (clickedElement, customFuntion, value) => {
        const tl = gsap.timeline();
        tl.to(clickedElement, {
            duration: 0.085,
            scale: 0.95,
            opacity: 0.85,
            background: '#F2F4F6',
            ease: "power4.out",

        });
        tl.to(clickedElement, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            background: '#FFF',
            ease: "elastic.out(1,0.6)",
        });

        setTimeout(() => {
            customFuntion(value);
        }, 350);
    };

    const [isClickdYes, setIsClickedYes] = useState(false);

    const { trackEvent } = useMixpanel();


    const [answer_1, setAnswer_1] = useState(null);
    const [answer_2, setAnswer_2] = useState(null);
    const [answer_3, setAnswer_3] = useState(null);


    const handleClickFirstPopup = (value) => {
        setSecondLoader(true);
        setIsFirstPopupShow(false);
        setTitle('Finding the best ad platforms for you...')
        trackEvent('onboarding_aiScan_question1', {
            is_succesful: showIndustry ? "TRUE" : "FALSE",
            answer_1: (value) ? value : "NULL",
            answer_2: (answer_2) ? answer_2 : "NULL",
            answer_3: (answer_3) ? answer_3 : "NULL",
            productlink_added: showIndustry ? "MANUAL" : "AI"
        }, true)

    };

    const handleClickSecondPopup = (value) => {
        setThirdLoader(true);
        setIsSecondPopupShow(false);
        setTitle('Discovering ways to help you grow...')
        trackEvent('onboarding_aiScan_question2', {
            is_succesful: showIndustry ? "TRUE" : "FALSE",
            answer_1: (answer_1) ? answer_1 : "NULL",
            answer_2: (value) ? value : "NULL",
            answer_3: (answer_3) ? answer_3 : "NULL",
            productlink_added: showIndustry ? "MANUAL" : "AI"
        }, true)
    };

    const handleClickThirdPopupNo = (value) => {
        trackEvent('onboarding_aiScan_question3', {
            is_succesful: showIndustry ? "TRUE" : "FALSE",
            answer_1: (answer_1) ? answer_1 : "NULL",
            answer_2: (answer_2) ? answer_2 : "NULL",
            answer_3: (value) ? value : "NULL",
            productlink_added: showIndustry ? "MANUAL" : "AI"
        }, true)

        if (isClickdYes) {
            history.push('/booster')
        } else {
            history.push('/thankyou')
        }
    };

    const handleClickPopupYes = (value) => {
        trackEvent('onboarding_aiScan_question3', {
            is_succesful: showIndustry ? "TRUE" : "FALSE",
            answer_1: (answer_1) ? answer_1 : "NULL",
            answer_2: (answer_2) ? answer_2 : "NULL",
            answer_3: (value) ? value : "NULL",
            productlink_added: showIndustry ? "MANUAL" : "AI"
        }, true)

        history.push('/booster')


    };





    return (
        <div className="ai-analyzer">
            <div className="ai-analyzer-top">
                <CustomTitle style={{ marginBottom: "24rem" }} title="Our AI is exploring new<br>ways to boost your sales" />

                {showIndustry ? (
                    <AIAnalyzerContent />
                ) : (
                    <div className={`ai-analyzer-top__img`}>
                        <AiAnalyzerImg />
                        <div className={`ai-analyzer-top__img--ai ${isFirstPopupShow || isSecondPopupShow || isThirdPopupShow ? 'blur' : ''}`}>
                            < IconAi />
                        </div>
                    </div>)}
            </div>
            <Loader progress={progress} tripleLoader={true} title={title} />
            {isFirstPopupShow &&
                <div className="popup">
                    <div className="popup-container">
                        <div className="popup__text">Videos work best for your industry</div>
                        <div className="popup__title">Want to make x2 more AI videos every month?</div>
                        <div className='choose-action'>
                            <div className="choose-action-btn" onClick={(event) => { setAnswer_1("NO"); handleClickChoose(event.currentTarget, handleClickFirstPopup, 'NO') }}>
                                <IconNo />
                                No
                            </div>
                            <div className="choose-action-btn" onClick={(event) => {
                                setAnswer_1("YES")
                                setIsClickedYes(true)
                                handleClickChoose(event.currentTarget, handleClickFirstPopup, "YES")
                            }}>
                                <IconYes />
                                Yes
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isSecondPopupShow &&
                <div className="popup">
                    <div className="popup-container">
                        <div className="popup__text">Best platforms for your ads: FB and IG</div>
                        <div className="popup__title">Want to cut your promotion fee from 12% to just 6%?</div>
                        <div className='choose-action'>
                            <div className="choose-action-btn" onClick={(event) => {
                                setAnswer_2("NO")
                                handleClickChoose(event.currentTarget, handleClickSecondPopup, "NO")
                            }
                            }>
                                <IconNo />
                                No
                            </div>
                            <div className="choose-action-btn" onClick={(event) => {
                                setIsClickedYes(true)
                                setAnswer_2("YES")
                                handleClickChoose(event.currentTarget, handleClickSecondPopup, "YES")
                            }}>
                                <IconYes />
                                Yes
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isThirdPopupShow &&
                <div className="popup">
                    <div className="popup-container">
                        <div className="popup__text">Expert advice helps you grow faster</div>
                        <div className="popup__title">Want tailored support
                            with x4 faster reply times?</div>
                        <div className='choose-action'>
                            <div className="choose-action-btn" onClick={(event) => {
                                setAnswer_3("NO")
                                handleClickChoose(event.currentTarget, handleClickThirdPopupNo, "NO")
                            }}>
                                <IconNo />
                                No
                            </div>
                            <div className="choose-action-btn" onClick={(event) => {
                                setIsClickedYes(true)
                                setAnswer_3("YES")
                                handleClickChoose(event.currentTarget, handleClickPopupYes, "YES")
                            }
                            }>
                                <IconYes />
                                Yes
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
