import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const unsplashApi = createApi({
    reducerPath: 'unsplashApi',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'https://corsproxy.io/?key=6093af64&url=https://unsplash.com'
        baseUrl: 'https://staging.zeely.link',
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    endpoints: (builder) => ({
        fetchPictures: builder.query({
            query: ({ query }) =>
                `/users/generate-images?query=${encodeURIComponent(query)}&per_page=${10}`,
            transformResponse: (response) =>
                response.results
                    .map((item) => item.urls['regular'])
                    .filter((url) => !url.includes('premium_photo'))
        })
    })
});

export const { useFetchPicturesQuery } = unsplashApi;
