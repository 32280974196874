import React from 'react'
import './style.scss'

export default function Loader({ progress, tripleLoader, title }) {



    return (
        <div style={{ width: "100%", margin: "0 auto", textAlign: "center" }} className='loader'>
            <div className="loader-top">
                <p>{title}</p>
                <span >{progress}%</span>
            </div>

            {tripleLoader ? (
                <div
                    style={{
                        position: "relative",
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: '4rem',
                    }}
                >


                    <div
                        style={{
                            position: "relative",
                            height: "6rem",
                            background: '#f2f4f6',
                            borderRadius: "100rem",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                                width: `${progress <= 33 ? progress * 3 : 100}%`,
                                background: "#000",
                                transition: "width 0.3s ease",
                            }}
                        ></div>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            height: "6rem",
                            background: '#f2f4f6',
                            borderRadius: "100rem",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                                width: `${(progress <= 66 && progress >= 34) ? ((progress - 33) * 3) : (progress >= 67 ? 100 : 0)}%`,
                                background: "#000",
                                transition: "width 0.3s ease",
                            }}
                        ></div>
                    </div>

                    <div
                        style={{
                            position: "relative",
                            height: "6rem",
                            background: '#f2f4f6',
                            borderRadius: "100rem",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                                width: `${(progress <= 100 && progress >= 67) ? ((progress - 66) * 3) : 0}%`,
                                background: "#000",
                                transition: "width 0.3s ease",
                            }}
                        ></div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        position: "relative",
                        height: "6rem",
                        background: '#f2f4f6',
                        borderRadius: "100rem",
                        overflow: "hidden",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: `${progress}%`,
                            background: "#000",
                            transition: "width 0.3s ease",
                        }}
                    ></div>
                </div>
            )}



        </div>
    )
}

