import React, { useState } from 'react'
import { useScrappingQuery, useScreenshotQuery } from '../../../../api/dataApi';

export default function AiAnalyzerImg() {

    const [url, setUrl] = useState(localStorage.getItem('site-url'))


    const { data: screenshotData, error: screenshotError, isLoading: isScreenshotLoading } = useScreenshotQuery(url);
    

    return (
        <div className="ai-analyzer-top__img--phone">
            <img src={process.env.PUBLIC_URL + '/img/phone_mokup.webp'} alt="" />
            <img src={screenshotData?.screenshotUrl} alt="" />
        </div>
    )
}
