import React, { useState, useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import MyButton from '../../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import './style.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as IconDomain } from '../../assets/icons/domain.svg';
import { useCheckSiteMutation, useFetchBusinessProfilesQuery } from '../../api/dataApi';
import useMixpanel from '../../hooks/useMixpanel';


const WebsiteNew = ({ setWhiteWrapper, setWelcometo }) => {
    useEffect(() => {
        setWhiteWrapper(true)
        setWelcometo(false)
    }, [])
    const [showPopup, setShowPopup] = useState(false);
        const [isFirst, setIsFirst] = useState(true);
    const history = useHistory();
    const [checkSite, { isCheckSite, checkSiteData, checkSiteError }] = useCheckSiteMutation();

    const [checkboxes, setCheckboxes] = useState({
        F: false,
    });
    const [errorUrl, setErrorUrl] = useState();
    const [name, setName] = useState('');

    const [error, setError] = useState(false);

    const areAllCheckboxesUnchecked = () => {
        return Object.values(checkboxes).every((isChecked) => !isChecked);
    };

    const { data: businessProfiles, isLoading: isLoadingProfiles } = useFetchBusinessProfilesQuery();

    const direction = businessProfiles?.data?.direction;

    const [buttonStatus, setButtonStatus] = useState('');

    const { trackEvent } = useMixpanel();


    const handleClick = () => {
        if (areAllCheckboxesUnchecked()) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 1000);
        } else {
            setButtonStatus('load')
            if (name.startsWith('https://') || name.startsWith('http://')) {
                checkWebsite(name);
            } else {
                checkWebsite(`https://${name}`);
            }
            setError(false);
        }
    };

    const handleInputChange = (event) => {
        const enteredValue = event.target.value.replace(/\s/g, '');

        setName(enteredValue);
        setErrorUrl(false)
        if (event.target.value == "") {
            setCheckboxes({ F: false, })
        } else {
            setCheckboxes({ F: true, })
        }
    };


    const isRootUrl = (url) => /^https?:\/\/[^\/]+\/?$/.test(url);

    const checkWebsite = async (url) => {
        const data = JSON.stringify({ url: url })
        try {
             if (isRootUrl(url)) {
                if (!isFirst) {
                    localStorage.setItem('site-url', url);
                    trackEvent('onboarding_productlink', {
                        productlink_added: 'AI'
                    }, true);
                    history.push('/product-loader')
                }
                setShowPopup(true)
            } else {
                localStorage.setItem('site-url', url);
                trackEvent('onboarding_productlink', {
                    productlink_added: 'AI'
                }, true);
                history.push('/product-loader')

            }
            // console.log('Check Site Result:', result);
        } catch (err) {
            trackEvent('onboarding_productlink', {
                productlink_added: 'MANUAL'
            }, true);

            if(businessProfiles?.data?.businessArea) {
                history.push('/company-name')
            } else {
                history.push('/industry-2')
            }
            if (err.message === "Network Error" && url.includes('www.')) {
                checkWebsite(removeWWW(url))
            } else {
                let errorMessage = err;
                if (err && err.response && err.response.data && err.response.data.message) {
                    errorMessage = err.response.data.message;
                }
                sendAlert(`https://staging.zeely.link/sites/check-user-site`, data, errorMessage);
                setButtonStatus('')
                setErrorUrl(true)
            }
        }
    };


    function removeWWW(url) {
        return url.replace('www.', ''); // or 'https://' if you prefer to maintain https
    }





    const sendAlert = async (path, data, errorLog) => {
        const now = new Date();
        const utcString = now.toISOString();

        const formdata = new FormData();
        formdata.append("domain", window.location.hostname);
        formdata.append("time", utcString);
        formdata.append("email", localStorage.getItem('email'));
        formdata.append("path", path);
        formdata.append("data", data);
        formdata.append("error", errorLog);
        console.log(formdata)
        try {
            const response = await axios.post("https://zee.sale/send-alert.php", formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };



    return (
        <div className='website page page--without-button'>

            <div className="website-top">
                <CustomTitle style={{ marginBottom: "8rem" }} title={`${direction === "PRODUCT" ? "Add your first product link" : "Add your first service link"}`} />
                <p style={{ marginBottom: "24rem" }} className="text">Our AI will analyze it to get insights into your brand</p>
                <div className={`input ${errorUrl || error ? 'error' : ''}`} style={{ marginBottom: "24rem", paddingLeft: "46rem", paddingRight: "16rem" }}>
                    <input
                        type="text"
                        placeholder={`${direction === "PRODUCT" ? "yoursite.com/product" : "yoursite.com/service"}`}
                        value={name}
                        onChange={handleInputChange}
                        autocapitalize="none"
                    />
                    <div className={`input-border ${name === '' ? 'empty' : ''}`}></div>
                    <IconDomain />
                    <span>Link</span>
                    {errorUrl && (
                        <div className="error-text">This domain name is unavailable. Try a different one.</div>
                    )}

                </div>
            </div>





            <div className="website-bottom">




                <div className="skip" onClick={() => {
                    trackEvent('onboarding_productlink', {
                        productlink_added: 'MANUAL'
                    }, true);
                    if(businessProfiles?.data?.businessArea) {
                        history.push('/company-name')
                    } else {
                        history.push('/industry-2')
                    }
                }}>
                    I don't have a link
                </div>
                <MyButton
                    // ref={nextPageButtonRef}
                    // buttonStatus={buttonStatus}
                    buttonTime={"0"}
                    buttonText={"Analyze with AI"}
                    buttonIcon={'no'}
                    onClick={() => handleClick()}
                    className={(areAllCheckboxesUnchecked()) ? (error ? 'error' : '') : undefined}
                >
                </MyButton>


            </div>


            {(showPopup && isFirst) &&
                <div className="popup">
                    <div className="popup-container">
                        <div className="popup__title">

                            {`${direction === "PRODUCT" ? "That doesn’t seem like a product link" : "That doesn’t seem like a service link"}`}
                        </div>
                        <div className="popup__text">
                            For better accuracy, use a direct link to the<br></br> {`${direction === "PRODUCT" ? "product" : "service"}`} page if you have one.
                        </div>
                        <MyButton
                            // ref={nextPageButtonRef}
                            //  buttonStatus={buttonStatus}
                            buttonTime={"0"}
                            buttonText={`${direction === "PRODUCT" ? "Provide product link" : "Provide service link"}`}
                            buttonIcon={'no'}
                            onClick={() => {
                                trackEvent('onboarding_check_productlink', {
                                    productlink_added: 'AI',
                                    user_action: 'provide'
                                }, true);
                                setIsFirst(false)
                                setShowPopup(false)
                                setName('')
                            }}
                        // className={(areAllCheckboxesUnchecked()) ? (error ? 'error' : '') : undefined}
                        >
                        </MyButton>
                        <div className="website-popup__keep"
                            onClick={() => {
                                if (name.startsWith('https://') || name.startsWith('http://')) {
                                    localStorage.setItem('site-url', name);
                                } else {
                                    localStorage.setItem('site-url', `https://${name}`);
                                }

                                trackEvent('onboarding_productlink', {
                                    productlink_added: 'AI'
                                }, true);
                                
                                trackEvent('onboarding_check_productlink', {
                                    productlink_added: 'AI',
                                    user_action: 'keep'
                                }, true);

                                history.push('/product-loader')
                            }}
                        >
                            Keep this link
                        </div>
                    </div>
                </div>
            }


        </div>
    );
}

export default WebsiteNew;

