import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom';

const CheckEmail = ({}) => {
    const history = useHistory();
    const allowedPaths = [
        '/password',
        '/loader',
        '/confirm-email',
        '/signup2',
        '/term',
        '/privacy',
        '/home1',
        '/home2',
        '/home3',
        '/home4',
        '/home5',
        '/home6',
        '/home7',
        '/home8',
        '/home9',
        '/home10',
        '/home11',
        '/home12',
        '/home13',
        '/home14',
        '/home15',
        '/home16',
        '/home17',
        '/home18',
        '/home19',
        '/home20',
        '/home21',
        '/home22',
        '/start',
        '/test-solid'
    ];
    useEffect(() => {
        if( window.location.pathname === '/trial') {
            if(!localStorage.getItem('email')) {
                history.push('/signup');
                return
            } else {
                return
            }
      
        } 
        if (!localStorage.getItem('accessToken') && !allowedPaths.includes(window.location.pathname)) {
            localStorage.setItem('checkEmailLInk', window.location.pathname);
            history.push('/signup2');
        }
    }, [window.location]);

    return (
        <></>
    )
}

export default CheckEmail