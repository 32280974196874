import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isDraftCreated: false,
};

const draftSlice = createSlice({
    name: "draft",
    initialState,
    reducers: {
        setDraftCreated: (state) => {
            state.isDraftCreated = true;
        },
    },
});

export const { setDraftCreated } = draftSlice.actions;
export default draftSlice.reducer;