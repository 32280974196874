import React, { useEffect, useState } from 'react'
import CustomTitle from '../../components/UI/title/CustomTitle'
import './style.scss'
import { ReactComponent as IconEdit } from '../../assets/icons/edit.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MyButton from '../../components/UI/button/MyButton';
import { useFetchBusinessProfilesQuery, useScrappingQuery, useUpdateBusinessProfileMutation } from '../../api/dataApi';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandName, setEditedIndustry, setEditedName, setIndustry, setScreenshot } from '../../store/scrappingSlice';
import useMixpanel from '../../hooks/useMixpanel';

export default function CheckDetails({ setWhiteWrapper, setWelcometo }) {
    useEffect(() => {
        setWhiteWrapper(true)
        setWelcometo(false)
    }, [])
    const history = useHistory();

    const { trackEvent } = useMixpanel();

    const { data: businessProfiles, isLoading: isLoadingProfiles } = useFetchBusinessProfilesQuery();
    const [updateProfile, { isLoading: updating, error: updateError }] = useUpdateBusinessProfileMutation();

    const { screenshot, industry, brandName, edited_name, edited_industry, adjusted_name, adjusted_industry } = useSelector((state) => state.scrapping);

    const dispatch = useDispatch();

    const [url, setUrl] = useState(localStorage.getItem('site-url'))

    const { data: scrappingData, error: scrappingError, isLoading: isScrapping } = useScrappingQuery({
        url: url,
    });


    useEffect(() => {
        if (scrappingData) {
            // console.log(scrappingData)
            if (!industry) {
                dispatch(setIndustry(scrappingData?.industry));
            }
            if (!brandName) {
                dispatch(setBrandName(scrappingData?.brandName));
            }
            dispatch(setScreenshot(scrappingData?.screenshot));
        }
    }, [scrappingData])





    const [industries, setIndustries] = useState()
    const [industryLabel, setIndustryLabel] = useState()

    useEffect(() => {
        axios.get('./data/industry-new.json')
            .then(response => {
                setIndustries(response.data);
            })
            .catch(error => {
                console.error('Error loading the JSON file:', error);
            });
    }, [])




    const industryData = industry;

    const findIndustry = (industries, label) => {
        return industries?.find(industry => (industry.label === label && industry.img === true));
    };

    useEffect(() => {
        if (industryData) {
            setIndustryLabel(findIndustry(industries, industryData));
        }
    }, [industryData, industries])

    const brandInitial = brandName ? brandName.charAt(0) : '';


    const handleUpdateProfile = async (data) => {
        try {
            const profileId = businessProfiles?.data?.id;
            console.log(businessProfiles?.data?.id)
            const data = {
                // ...businessProfiles?.data,
                companyName: brandName,
                businessArea: industry,
            }
            if (profileId) {
                await updateProfile({ id: profileId, data }).unwrap();
                console.log('Profile updated successfully');
            } else {
                console.error('Profile ID not found');
            }
        } catch (err) {
            console.error('Error updating profile:', err);
        }
    };

    // console.log(industryLabel)
    return (

        <div className='check-details'>
            <div className="check-details-top">
                <CustomTitle style={{ marginBottom: "24rem" }} title="Does this look right to you?" />
                <div className="check-details-items">
                    <div className="check-details-item" onClick={() => {
                        dispatch(setEditedName(true))
                        history.push('/company-name-save')
                    }}>
                        <div className="check-details-item__img">
                            <span>{brandInitial}</span>
                        </div>
                        <div className="check-details-item-info">
                            <p>Company name</p>
                            <h4>{brandName}</h4>
                        </div>
                        <div className="check-details-item-edit">
                            <IconEdit />
                        </div>
                    </div>
                    <div className="check-details-item" onClick={() => {
                        dispatch(setEditedIndustry(true))
                        history.push('/industry-save')
                    }}>
                        <div className="check-details-item__img">
                            <img src={process.env.PUBLIC_URL + `/img/business-industry/${industryLabel ? industryData + '.webp' : 'barea.png'}`} alt="" />
                        </div>
                        <div className="check-details-item-info">
                            <p>Industry</p>
                            <h4>{industry}</h4>
                        </div>
                        <div className="check-details-item-edit" >
                            <IconEdit />
                        </div>
                    </div>
                </div>
            </div>
            <MyButton
                // ref={nextPageButtonRef}
                buttonStatus={''}
                buttonTime={"0"}
                buttonText={"Continue"}
                buttonIcon={'no'}
                onClick={() => {
                    trackEvent('onboarding_product_check', {
                        industry: industry,
                        user_edited_name: edited_name ? 'TRUE' : 'FALSE',
                        user_edited_industry: edited_industry ? 'TRUE' : 'FALSE',
                        user_adjusted_name: adjusted_name ? 'TRUE' : 'FALSE',
                        user_adjusted_industry: adjusted_industry ? 'TRUE' : 'FALSE',
                        company_determination: 'AI'
                    }, true);
                    handleUpdateProfile()
                    history.push('/ai-loader')
                }
                }
            >
            </MyButton>
        </div>
    )
}
