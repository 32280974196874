import React, { useEffect, useRef, useState } from 'react'
import './style.scss';
import CustomTitle from '../../components/UI/title/CustomTitle';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MyButton from '../../components/UI/button/MyButton';
import { useForgotPasswordMutation, useVerifyCodeMutation } from '../../api/dataApi';
import CryptoJS from 'crypto-js';
import useMixpanel from '../../hooks/useMixpanel';

export default function ConfirmEmail({ setWhiteWrapper, setWelcometo, welcometoShow  }) {

    useEffect(() => {
        setWhiteWrapper(true)
        setWelcometo(false)
    }, [])

    const history = useHistory();
    const inputRefs = useRef([]);
    const [code, setCode] = useState(Array(6).fill(''));
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const [error, setError] = useState('');
    const { trackP1Event } = useMixpanel();
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').trim().slice(0, 6);
        setError('');
        if (pastedData.length === inputRefs.current.length) {

            const splitData = pastedData.split('');
            setCode(splitData);
            const lastInputField = inputRefs.current[inputRefs.current.length - 1];
            if (lastInputField) {
                lastInputField.focus();
            }
        }
    };



    const [forgotPassword, { isLoading, isSuccess, isError }] = useForgotPasswordMutation();

    useEffect(() => {
        if(!welcometoShow) {
            forgotPassword(localStorage.getItem('email'));
        }
    }, [welcometoShow])



    const handleChangeCode = (index) => (e) => {
        const newCode = [...code];
        const { value } = e.target;
        setError('');
        if (value === '' && newCode[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        newCode[index] = value.toUpperCase().replace(/[^A-Z0-9]/g, '') || '';
        setCode(newCode);

        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index) => (e) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            inputRefs.current[index - 1].focus();
        }
    };

    const isFormComplete = () => {
        return code.every(digit => digit.trim() !== '');
    };

    const [timer, setTimer] = useState(30);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    const [verifyCode, { isLoading: isVerifying, isSuccess: isVerified, isError: isVerificationError }] = useVerifyCodeMutation();


    const secretKey = 'j5wbjG3jNJMCSI9';

    const encryptText = (text) => {
        const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
        return ciphertext;
    };


    const handleClick = async () => {
        if (isFormComplete()) {
            try {
                const response = await verifyCode({ email: localStorage.getItem('email'), code: code.join('') });
                if (response.error) {
                    setError('Sorry, you entered the wrong code. Please try again.');
                }
                else {
                    const encrypted = await encryptText(code.join(''), secretKey);
                    localStorage.setItem('code', encrypted);
                    trackP1Event('onboarding_confirm_email', {})
                    history.push({
                      pathname: '/password',
                      search: `?${queryParams.toString()}`,
                    });
              
                }
            } catch (error) {
                setError('Sorry, you entered the wrong code. Please try again.');
            }
        } else {
            setError('Sorry, you entered the wrong code. Please try again.');
        }
    }

    return (
        <div className='confirm-email'>
            <div className="confirm-email-top">
                <CustomTitle
                    style={{ marginBottom: "12rem" }}
                    title={`Enter confirmation code`}
                />
                <p className="text" style={{ marginBottom: "12rem" }}>Enter the confirmation code we sent a 6-digit code to you at {localStorage.getItem('email')}</p>
                <div className="code-inputs">
                    {code.map((digit, index) => (
                        <input
                            key={index}
                            type="tel"
                            maxLength="1"
                            value={digit}
                            onKeyDown={handleKeyDown(index)}
                            onChange={handleChangeCode(index)}
                            onPaste={handlePaste}
                            ref={(el) => inputRefs.current[index] = el}
                            className={`form__input ${error ? 'error' : ''}`}

                        />
                    ))}
                    {error && (
                        <div className="confirm-email__error">
                            Sorry, you entered the wrong code. Please try again.
                        </div>
                    )}
                </div>
                <div className="confirm-email-help">
                    Need help? <a href="https://help.zeely.app/en/">Contact us</a>
                </div>
            </div>
            <div className="confirm-email__resend" onClick={() => {
                if (timer === 0) {

                    forgotPassword(localStorage.getItem('email'));
                    setTimer(30);
                }
            }}>
                {timer === 0 ? (
                    <span className="resend-code">Resend code</span>
                ) : (
                    <>Resend code in 00:{timer < 10 ? `0${timer}` : timer}</>
                )}
            </div>
            <MyButton buttonText="Confirm" buttonIcon="no" onClick={handleClick}
                buttonStatus={isVerifying ? 'load' : ''}
                className={error ? 'error' : ''}
            />


        </div>
    )
}
