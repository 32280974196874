import { useEffect, useState } from "react";
import { useGetInfoQuery, useSendFacebookEventMutation } from "../api/dataApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import ReactPixel from "react-facebook-pixel";

const useFaceBook = () => {
  const history = useHistory();
  const { data: userInfo } = useGetInfoQuery();
  const [sendFacebookEvent] = useSendFacebookEventMutation();
  const queryParams = new URLSearchParams(window.location.search);

  function getFBP() {
    const name = "_fbp=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  function getFBC() {
    const name = "_fbc=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  const fbp = getFBP();
  const fbc = getFBC();

  const [ip, setIp] = useState(null);

  useEffect(() => {
    // Fetch the IP address from ipify
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error("Error fetching IP address:", error));
  }, []);

  const pathname = history?.location?.pathname;

  const storageKey = `eventId_${pathname}`;
  let eventId = localStorage.getItem(storageKey);
  if (!eventId) {
    eventId = `event_${Date.now()}_${Math.random().toString(36).substring(2)}`;
    localStorage.setItem(storageKey, eventId);
  }

  const sendFb = (eventName, data, dataPixel) => {
    const pathname = history?.location?.pathname;
    const fullJSON = localStorage.getItem("business-profiles");
    const profile = JSON.parse(fullJSON)?.[0];

    const dataFB = {
      clineUserAgent: navigator.userAgent,
      websiteUrl: window.location.href,

      path: pathname,
      ...(localStorage.getItem("email") && {
        email: localStorage.getItem("email"),
      }),
      ...(profile?.userName && { name: profile?.userName }),
      ...(fbp && { fbp: fbp }),
      ...(fbc
        ? { fbc: fbc }
        : localStorage.getItem("fbclid")
        ? { fbclid: localStorage.getItem("fbclid") }
        : {}),
      ...(userInfo?.data?.id ? { externalId: userInfo?.data?.id } : {}),
      ...(ip && { clientIpAddress: ip }),
      ...(localStorage.getItem('testevent') && { testEvent: localStorage.getItem('testevent') }),
    };

    const storageKey = `sentEvents_${pathname}`;

    const sentEvents = JSON.parse(sessionStorage.getItem(storageKey) || "[]");

    if (sentEvents.includes(eventName)) {
      return;
    }

    const newEventId = `event${Date.now()}${Math.random()
      .toString(36)
      .substring(2)}`;

    if (window.fbq) {
      window.fbq(
        "track",
        eventName,
        {
          ...(userInfo?.data?.id ? { external_id: userInfo?.data?.id } : {}),
          ...dataPixel,
        },
        {
          eventID: newEventId,
        }
      );
    }
    if(eventName != 'PageView') {
      sendFacebookEvent({
        pixelId: "507395697701950",
        eventName: eventName,
        userId: userInfo?.data?.id,
        eventId: newEventId,
        ...dataFB,
        ...data,
      });
    }
    
    sentEvents.push(eventName);
    sessionStorage.setItem(storageKey, JSON.stringify(sentEvents));
  };

  return { sendFb };
};

export default useFaceBook;
