
import { configureStore } from '@reduxjs/toolkit';
import overflowReducer from './overflowBlockSlice';
import blockClassNameReducer from "./blockClassNameSlice"; 
import { dataApi } from '../api/dataApi.js';
import { unsplashApi } from '../api/unsplashApi.js';
import scrappingReducer from './scrappingSlice';
import draftReducer from "./draftSlice";


const store = configureStore({
  reducer: {
    overflow: overflowReducer,
    scrapping: scrappingReducer,
    blockClassName: blockClassNameReducer,
    [dataApi.reducerPath]: dataApi.reducer,
    [unsplashApi.reducerPath]: unsplashApi.reducer,
    draft: draftReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(dataApi.middleware, unsplashApi.middleware),
});

export default store;
