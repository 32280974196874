import React, { useEffect, useRef, useState } from 'react'
import CustomTitle from '../../components/UI/title/CustomTitle'
import Loader from '../../components/Loader/Loader'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useCreateDraftMutation, useFetchBusinessProfilesQuery, useScrappingQuery, useScreenshotQuery } from '../../api/dataApi';
import WebsiteLoaderImg from './components/WebsiteLoaderImg/WebsiteLoaderImg';
import './style.scss'
import useMixpanel from '../../hooks/useMixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { setDraftCreated } from '../../store/draftSlice';


export default function WebsiteLoader({ setWhiteWrapper, setWelcometo }) {
        useEffect(() => {
            setWhiteWrapper(true)
            setWelcometo(false)
        }, [])
    const history = useHistory();
    const [progress, setProgress] = useState(0);

    const [url, setUrl] = useState(localStorage.getItem('site-url'))

    const { trackEvent } = useMixpanel();



    const { data: scrappingData, error: scrappingError, isLoading: isScrapping } = useScrappingQuery({
        url: url,
    });

    const [createDraft, { isLoading: isCreatingDraft, data: draftData, error: draftError }] = useCreateDraftMutation();

    const dispatch = useDispatch();
    const isDraftCreated = useSelector((state) => state.draft.isDraftCreated);
    
    const { data: businessProfiles, isLoading: isLoadingProfiles } = useFetchBusinessProfilesQuery();


    useEffect(() => {
        if (!isDraftCreated && businessProfiles) {
            createDraft({ 
                link: url,
                ... businessProfiles.data.productsType && {productType: businessProfiles.data.productsType === 'Products I buy or make myself' ? 
                    'Physical product' : businessProfiles.data.productsType === 'Digital products' ? 
                    'Digital product' : businessProfiles.data.productsType === 'Dropshipping products' ? 
                    'Physical product' : 'Service'},
                ... businessProfiles.data.sitePlatform && {source: businessProfiles.data.sitePlatform?.toUpperCase()}
             });
            dispatch(setDraftCreated());
        }
    }, [isDraftCreated, businessProfiles]);
    
    const { data: screenshotData, error: screenshotError, isLoading: isScreenshotLoading } = useScreenshotQuery(url);

    
    useEffect(() => {
        console.log(businessProfiles?.data?.businessArea)
        if (scrappingError && progress === 100) {
            trackEvent('onboarding_productlink_scraped', { is_succesful: "FALSE" }, true);
            if(businessProfiles?.data?.businessArea) {
                history.push('/company-name')
            } else {
                history.push('/industry-2')
            }
        }
    }, [scrappingError, progress, businessProfiles])

    useEffect(() => {
        let timeout = null;

        if (isScrapping && progress === 100) {
            timeout = setTimeout(() => {
                trackEvent('onboarding_productlink_scraped', { is_succesful: "FALSE" }, true);
                if(businessProfiles?.data?.businessArea) {
                    history.push('/company-name')
                } else {
                    history.push('/industry-2')
                }
            }, 30000);
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [isScrapping, progress, businessProfiles]);

    useEffect(() => {
        const target = 100;
        const intervalTime = !isScrapping && scrappingData ? 50 : progress > 90 ? 1500 : 166;

        const interval = setInterval(() => {
            setProgress((prev) => (prev < target ? prev + 1 : target));
        }, intervalTime);

        return () => clearInterval(interval);
    }, [isScrapping, scrappingData, progress]);


    useEffect(() => {
        if (progress === 100 && scrappingData) {
            if (scrappingData.brandName && scrappingData.industry) {
                trackEvent('onboarding_productlink_scraped', { is_succesful: "TRUE" }, true);
                history.push('/product-check')
            }
            else {
                trackEvent('onboarding_productlink_scraped', { is_succesful: "FALSE" }, true);
                if(businessProfiles?.data?.businessArea) {
                    history.push('/company-name')
                } else {
                    history.push('/industry-2')
                }
            }
        }
    }, [progress, scrappingData, businessProfiles])


    return (
        <div className='website-loader page page--without-button '>

            <div className="page-top">
                <div className="website-loader-top">
                    <CustomTitle style={{ marginBottom: "32rem" }} title="Hold tight for AI magic!" />
                    <WebsiteLoaderImg />
                </div>
            </div>


            <Loader progress={progress} title={'Analyzing your site...'} />
        </div>
    )
}
