import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQueryWithReauth = async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
        baseUrl: 'https://staging.zeely.link',
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    });

    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            const refreshResult = await baseQuery(
                {
                    url: '/auth/refresh',
                    method: 'POST',
                    body: { refreshToken }
                },
                api,
                extraOptions
            );

            if (refreshResult.data) {
                // localStorage.setItem('accessToken', refreshResult.data.accessToken);
                // localStorage.setItem('refreshToken', refreshResult.data.refreshToken);
                result = await baseQuery(args, api, extraOptions);
            } else {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
            }
        } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
        // window.location.href = '/login'; // Укажите правильный путь к странице логина
    }

    return result;
};

export const dataApi = createApi({
    reducerPath: 'dataApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['CheckSite', 'Scrapping', 'BusinessProfiles', 'register', 'Screenshot', 'Users'],
    endpoints: (builder) => ({
        getInfo: builder.query({
            query: () => '/users',
            providesTags: ['Users']
        }),
        updateUsers: builder.mutation({
            query: (data) => ({
                url: '/users',
                method: 'PATCH',
                body: data
            }),
            providesTags: ['Users']
        }),
        checkSite: builder.mutation({
            query: (url) => ({
                url: '/sites/check-user-site',
                method: 'POST',
                body: { url }
            }),
            invalidatesTags: ['CheckSite']
        }),

        register: builder.mutation({
            query: (email) => ({
                url: '/users/register',
                method: 'POST',
                body: { email,  "withAuth": true}
            }),
            invalidatesTags: ['register']
        }),

        login: builder.mutation({
            query: (data) => ({
                url: '/users/login',
                method: 'POST',
                body: data
            })
        }),

        scrapping: builder.query({
            query: ({ url } = {}) =>
                `https://staging.zeely.link/products/external/scrapping?link=${url}`,
            providesTags: ['Scrapping']
        }),
        screenshot: builder.query({
            query: (url) =>
                `https://staging.zeely.link/products/external/site-screenshot?link=${url}`,
            providesTags: ['Screenshot']
        }),
        checkSubscription: builder.query({
            query: (email) => `/subscriptions/current/check?email=${email}`
        }),

        fetchBusinessProfiles: builder.query({
            query: () => '/business-profiles',
            invalidatesTags: ['BusinessProfiles']
        }),
        searchBusinessProfiles: builder.query({
            query: (email) => `/business-profiles/search?email=${email}`,
            providesTags: ['BusinessProfiles']
        }),

        createBusinessProfile: builder.mutation({
            query: (data) => ({
                url: '/business-profiles',
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: ['BusinessProfiles']
        }),

        updateBusinessProfile: builder.mutation({
            query: ({ id, data }) => ({
                url: `/business-profiles/${id}`,
                method: 'PATCH',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: ['BusinessProfiles']
        }),

        forgotPassword: builder.mutation({
            query: (email) => ({
                url: '/users/forgot-password',
                method: 'POST',
                body: { email,
                    forgot: false
                 }
            })
        }),
        verifyCode: builder.mutation({
            query: (data) => ({
                url: '/users/verify-code',
                method: 'POST',
                body: data
            })
        }),

        changePassword: builder.mutation({
            query: (data) => ({
                url: '/users/change-password-by-code',
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }),

        changePasswordNew: builder.mutation({
            query: (data) => ({
                url: '/users/change-password',
                method: 'PATCH',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }),
        sendFacebookEvent: builder.mutation({
            query: (data) => ({
                url: '/facebook-profiles/v2/send-facebook-event',
                method: 'POST',
                body: data
            })
        }),
        checkEmail: builder.mutation({
            query: (email) => ({
                url: '/users/email-check',
                method: 'POST',
                body: { email },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }),
        createDraft: builder.mutation({
            query: (data) => ({
                url: '/products/external/drafts',
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }),
        changeEmail: builder.mutation({
            query: (data) => ({
                url: '/users/change-email',
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }),
        createFunnel: builder.mutation({
            query: (data) => ({
                url: '/pages/v2/funnels',
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        })
    })
});

export const {
    useCheckSiteMutation,
    useScrappingQuery,
    useFetchBusinessProfilesQuery,
    useCreateBusinessProfileMutation,
    useUpdateBusinessProfileMutation,
    useRegisterMutation,
    useCheckSubscriptionQuery,
    useForgotPasswordMutation,
    useVerifyCodeMutation,
    useChangePasswordMutation,
    useChangePasswordNewMutation,
    useLoginMutation,
    useScreenshotQuery,
    useGetInfoQuery,
    useSendFacebookEventMutation,
    useSearchBusinessProfilesQuery,
    useCheckEmailMutation,
    useChangeEmailMutation,
    useUpdateUsersMutation,
    useCreateDraftMutation,
    useCreateFunnelMutation
} = dataApi;
