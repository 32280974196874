import React, { useState, useEffect, useRef } from "react";
import CustomTitle from "../../components/UI/title/CustomTitle";
import "./signup.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { ReactComponent as IconEmail } from "../../assets/icons/email.svg";
import { ReactComponent as IconPassword } from "../../assets/icons/password.svg";
import { ReactComponent as IconEye } from "../../assets/icons/eye.svg";
import { ReactComponent as IconNotEye } from "../../assets/icons/not-eye.svg";
import MyButton from "../../components/UI/button/MyButton";
import { useChangeEmailMutation, useChangePasswordMutation, useChangePasswordNewMutation, useCheckEmailMutation, useCheckSubscriptionQuery, useCreateBusinessProfileMutation, useFetchBusinessProfilesQuery, useGetInfoQuery, useLoginMutation, useUpdateBusinessProfileMutation, useUpdateUsersMutation } from "../../api/dataApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CryptoJS from 'crypto-js';
import { Helmet } from "react-helmet";

import useMixpanel from "../../hooks/useMixpanel";

const SignupNew = ({ setWhiteWrapper, setWelcometo }) => {

  useEffect(() => {
    setWhiteWrapper(true)
    setWelcometo(false)
  }, [])

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();
  const [title, setTitle] = useState("Set up your password");
  const { trackP1Event } = useMixpanel();
  const [inputEmail, setInputEmail] = useState(
    localStorage.getItem('email') ? localStorage.getItem('email') : ""
  );

  const [errorEmail1, setErrorEmail1] = useState(false);
  const [msgEmail1, setMsgEmail1] = useState("");
  const [errorEmail2, setErrorEmail2] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [placeholderPassword, setPlaceholderPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [msgPassword, setMsgPassword] = useState("");
  const [inputPassword2, setInputPassword2] = useState("");
  const [errorPassword2, setErrorPassword2] = useState(false);
  const [msgPassword2, setMsgPassword2] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [disabledEmail, setDisabledEmail] = useState(false);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;

  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("new-account")) {
      setDisabledEmail(true)
    }
  }, [])

  const [changePassword, { isLoading: isChangingPassword, error: changePasswordError }] = useChangePasswordMutation();
  const [changePasswordNew, { isLoading: isChangingPasswordNew, error: changePasswordNewError }] = useChangePasswordNewMutation();
  const [checkEmail, { isLoading: isCheckingEmail, error: checkEmailError }] = useCheckEmailMutation();
  const [changeEmail, { isLoading: isChangingEmail, error: changeEmailError }] = useChangeEmailMutation();
  const [updateUsers, { isLoading: isUpdatingUserEmail, error: updateUsersError }] = useUpdateUsersMutation();

  const [login, { isLoading: isLoggingIn, error: loginError }] = useLoginMutation();



  const [isLogin, setIsLogin] = useState(false);

  const [createBusinessProfile, { isLoading, error }] =
    useCreateBusinessProfileMutation();

  const [updateProfile, { isLoading: updating, error: updateError }] = useUpdateBusinessProfileMutation();


  const { data: userInfo, refetch: refecthUsers } = useGetInfoQuery();
  const { data: businessProfiles, error: errorbusinessProfiles, isLoading: isLoadingBusinessProfiles, refetch } = useFetchBusinessProfilesQuery();
  const { data: subscriptionData, error: subscriptionError, isLoading: isSubscriptionLoading } = useCheckSubscriptionQuery(localStorage.getItem('email'));


  useEffect(() => {
    refetch()
  }, [])

  const logIn = async () => {
    const data = {
      email: inputEmail,
      password: inputPassword,
      os: "web",
      withAuth: true
    };
    try {
      const response = await login(data).unwrap();
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('authToken', response.data.authToken);
      refecthUsers()
      if (localStorage.getItem("new-account")) {
        checkSubscribe()
        // handleCreateProfile()
      } else {
        if (localStorage.getItem("name")) {
          const changeName = await updateUsers({
            name: localStorage.getItem("name"),
          });
        }
        await refetch()

        setIsLogin(true);
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    if (isLogin && !isLoadingBusinessProfiles) {
      if (errorbusinessProfiles?.data?.message === "Business profile not found") {
        handleCreateProfile()
        setIsLogin(false)
      }
      if (businessProfiles) {
        handleUpdateProfile()
        setIsLogin(false)
      }
    }
  }, [isLogin, isLoadingBusinessProfiles, errorbusinessProfiles, businessProfiles])

  const handleCreateProfile = async () => {

    const fullJSON = localStorage.getItem('business-profiles');
    const profile = JSON.parse(fullJSON)?.[0];
    const data = {
      direction: profile?.direction
        ? profile.direction
        : "PRODUCT",
      ...localStorage.getItem('email') && { email: localStorage.getItem('email') },
      ...localStorage.getItem('name') && { name: localStorage.getItem('name') },
      ...profile && profile,
    };

    try {
      await createBusinessProfile(data).unwrap();
      checkSubscribe()
      refetch()

      // console.log('Профиль успешно создан');
    } catch (err) {
      console.error("Ошибка создания профиля:", err);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      await businessProfiles

      const profileId = businessProfiles?.data?.id;
      const fullJSON = localStorage.getItem('business-profiles');
      const profile = JSON.parse(fullJSON)?.[0];
      const data = {
        direction: profile?.direction
          ? profile.direction
          : "PRODUCT",
        ...localStorage.getItem('email') && { email: localStorage.getItem('email') },
        ...localStorage.getItem('name') && { name: localStorage.getItem('name') },
        ...profile && profile,
      };
      if (profileId) {
        await updateProfile({ id: profileId, data }).unwrap();
        refetch()
        checkSubscribe()
      }
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  const checkSubscribe = () => {
    axios
      .get("https://staging.zeely.link/subscriptions/current", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        redirect: "follow",
      })
      .then((response) => {
        console.log(response.data.data);
        localStorage.setItem("userId", response.data.data.userId);
        trackP1Event('onboarding_signup_password', {
          existing_user: localStorage.getItem("new-account") ? 'FALSE' : 'TRUE',
          email_changed: (localStorage.getItem("email") != inputEmail) ? 'TRUE' : 'FALSE',
        })
        if (response?.data.data?.subscription?.bundleStatus === 'ACTIVE') {
          history.push({
            pathname: '/thankyou',
          })
        } else {
          history.push({
            pathname: '/product-link',
            search: `?${queryParams.toString()}`,
          });
        }

      })
      .catch((error) => {
        trackP1Event('onboarding_signup_password', {
          existing_user: localStorage.getItem("new-account") ? 'FALSE' : 'TRUE',
          email_changed: (localStorage.getItem("email") != inputEmail) ? 'TRUE' : 'FALSE',
        })
        history.push({
          pathname: '/product-link',
          search: `?${queryParams.toString()}`,
        });
      });
  };




  const inputRef = useRef(null);

  const handleChange = () => {
    inputRef.current?.focus();
  };

  const [showLastSymbol, setShowLastSymbol] = useState(false);

  const handlePasswordChange = (e) => {
    setErrorEmail1(false);
    setErrorPassword2(false);
    setErrorPassword(false);
    setShowLastSymbol(true);

    setInputPassword(e.target.value);
  };

  const handlePassword2Change = (e) => {
    setErrorEmail1(false);
    setErrorPassword2(false);
    setErrorPassword(false);
    setErrorPassword2(false);

    setInputPassword2(e.target.value);

  };

  useEffect(() => {
    if (
      !emailRegex.test(inputEmail) ||
      inputPassword.length < 5 ||
      inputPassword !== inputPassword2
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [inputEmail, inputPassword, inputPassword2]);

  const secretKey = 'j5wbjG3jNJMCSI9';

  const decryptText = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const original = bytes.toString(CryptoJS.enc.Utf8);
    return original;
  };

  const handleSubmit = async () => {
    setErrorEmail1(false);
    setErrorPassword(false);
    setErrorPassword2(false);

    if (!emailRegex.test(inputEmail)) {
      setErrorEmail1(true);
      setMsgEmail1("That email doesn't look right. Please correct it.");
    }

    if (inputPassword.length < 5) {
      setErrorPassword(true);
      setMsgPassword2("Your password needs to be at least 5 characters");
    }

    if (inputPassword !== inputPassword2) {
      setErrorPassword2(true);
      setMsgPassword2("Those passwords don’t match");
    }

    if (!emailRegex.test(inputEmail) || inputPassword.length < 5 || inputPassword !== inputPassword2) {
      return;
    }

    setStatus("load");

    const data = {
      email: localStorage.getItem("email"),
      code: decryptText(localStorage.getItem("code")),
      newPassword: inputPassword,
    };

    const dataNew = {
      oldPassword: decryptText(localStorage.getItem("code")),
      newPassword: inputPassword
    }

    try {
      if (localStorage.getItem("new-account")) {
        if (localStorage.getItem("email") != inputEmail) {
          const checkEmailResponse = await checkEmail(inputEmail);
          console.log(checkEmailResponse.data.data);
          if (checkEmailResponse?.data?.data?.isRegistered) {
            setErrorEmail1(true);
            setMsgEmail1("This email is already registered");
            return
          } else {

            const changeEmailResponse = await updateUsers({
              email: inputEmail,
              oldEmail: localStorage.getItem("email"),
            });


            await businessProfiles
            const profileId = businessProfiles?.data?.id;
            const data = {
              email: inputEmail
            }
            await updateProfile({ id: profileId, data }).unwrap();
            refetch()

          }
        }
        const response = await changePasswordNew(dataNew);
        if (response.error) {
          setStatus("");
          // setError('Sorry, you entered the wrong code. Please try again.');
        } else {

          logIn()
        }
      } else {
        // handleUpdateProfile()
        const response = await changePassword(data);
        if (response.error) {
          setStatus("");
          // setError('Sorry, you entered the wrong code. Please try again.');
        } else {
          logIn()
        }
      }
    } catch (error) {
      setStatus("");
      console.error("Error changing password:", error);
    }
  };


  return (
    <div className="signup page page--without-button">
      {/* <PageContent> */}
      {/* <div className="page-content"> */}
      <div className="signup-top">
        <CustomTitle style={{ marginBottom: "24rem" }} title={title} />
        <Helmet>
          <meta name="theme-color" content="#fff"></meta>
        </Helmet>
        <div
          className={`input input-email ${disabledEmail ? 'disabled' : ''} ${errorEmail1 ? "error" : ""}`}
          style={{
            paddingLeft: "46rem",
            paddingRight: disabled ? "70rem" : "",
          }}
        >
          <input
            ref={inputRef}
            type="email"
            value={inputEmail}
            onChange={(e) => {
              setErrorEmail1(false);
              setErrorPassword2(false);
              setErrorPassword(false);
              setInputEmail(e.target.value.toLowerCase());
            }}
            placeholder="Enter your email"
            name="email1"
          />
          <IconEmail />
          <span>Email</span>
          <div className={`input-border `}></div>
          {!disabledEmail && (
            <div className="change" onClick={handleChange}>
              Change
            </div>
          )}


          {errorEmail1 && (
            <div className="error-text" style={{ marginLeft: "-46rem" }}>
              {msgEmail1}
            </div>
          )}
        </div>

        <div
          className={`input ${errorPassword ? "error" : ""}`}
          style={{
            marginTop: "10rem",
            paddingLeft: "46rem",
            paddingRight: "46rem",
          }}
        >
          <input
            type={showPassword ? "text" : "password"}
            value={inputPassword}
            onChange={handlePasswordChange}
            onBlur={() => {
              if (inputPassword.length < 5) {
                setErrorPassword(true);
                setMsgPassword2("Your password needs to be at least 5 characters");
              }
            }

            }
            placeholder="Enter your password"
            name="password"
            autocomplete="off"
          />
          <div className="mask">
          </div>
          <div className={`input-border `}></div>
          <IconPassword />
          <span>Password</span>
          <div className="show" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <IconEye /> : <IconNotEye />}
          </div>
          {/* {errorPassword && (
            <div className="error-text" style={{ marginLeft: "-46rem" }}>
              {msgPassword}
            </div>
          )} */}
        </div>

        <div
          className={`input ${errorPassword2 ? "error" : ""}`}
          style={{
            marginTop: "10rem",
            paddingLeft: "46rem",
            paddingRight: "46rem",
          }}
        >
          <input
            type={showPassword ? "text" : "password"}
            value={inputPassword2}
            onChange={handlePassword2Change}
            onBlur={() => {
              if (inputPassword !== inputPassword2) {
                setErrorPassword2(true);
                setMsgPassword2("Those passwords don’t match");
              }
            }}
            placeholder="Repeat your password"
            name="password"
            autocomplete="off"
          />
          <div className={`input-border `}></div>
          <IconPassword />
          <span>Repeat password</span>
          <div className="show" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <IconEye /> : <IconNotEye />}
          </div>
          {(errorPassword2 || errorPassword) && (
            <div className="error-text" style={{ marginLeft: "-46rem" }}>
              {msgPassword2}
            </div>
          )}
        </div>
      </div>
      {/* </div> */}

      {/* </PageContent> */}


      <MyButton
        // ref={nextPageButtonRef}
        buttonStatus={status}
        buttonIcon={"no"}
        buttonText={"Continue"}
        onClick={() => handleSubmit()}
        className={`${disabled ? "disable" : ""} ${errorEmail1 || errorPassword || errorPassword2 ? "error" : ""
          }`}
      ></MyButton>
    </div>
  );
};

export default SignupNew;
