import React, { useEffect } from 'react'
import { useFetchPicturesQuery } from '../../api/unsplashApi';
import { useFetchBusinessProfilesQuery } from '../../api/dataApi';

export default function AIAnalyzerContent() {


    const { data: businessProfiles, isLoading: isLoadingProfiles } = useFetchBusinessProfilesQuery();
    const industry = localStorage.getItem('industry') ?? businessProfiles?.data?.businessArea;

    const { data: picturesData, error: fetchError } = useFetchPicturesQuery({ query: industry });


    return (
        <div className="ai-analyzer-top__industry-wrap">
            <div className="ai-analyzer-top__industry">
                <div className="ai-analyzer-top__industry-item">
                    <img src={process.env.PUBLIC_URL + '/img/i1.jpg'} alt="" />
                    <span>Dog training</span>
                </div>
                <div className="ai-analyzer-top__industry-item">
                    <img src={industry === 'E-commerce' ? process.env.PUBLIC_URL + '/img/industry/E-commerce.webp' : (Array.isArray(picturesData) && picturesData[0]) ?? process.env.PUBLIC_URL + '/img/i1.jpg'} alt="" />
                    <span><div>{industry}</div></span>
                </div>
                <div className="ai-analyzer-top__industry-item">
                    <img src={process.env.PUBLIC_URL + '/img/i3.jpg'} alt="" />
                    <span>Home Cleaning</span>
                </div>
            </div>
        </div>
    )
}
