import React, { useState, useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import MyButton from '../../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import axios from 'axios';
import './style.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { setAdjustedIndustry, setBrandName } from '../../store/scrappingSlice';
import useMixpanel from '../../hooks/useMixpanel';
import { useFetchBusinessProfilesQuery, useUpdateBusinessProfileMutation } from '../../api/dataApi';


const CompanyName = ({ save, setWhiteWrapper, setWelcometo }) => {

    const history = useHistory();
    const { brandName } = useSelector((state) => state.scrapping);
    const dispatch = useDispatch();

    useEffect(() => {
        setWhiteWrapper(true)
        setWelcometo(false)
    }, [])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);


    const [name, setName] = useState(save ? brandName : '');

    const handleInputChange = (event) => {
        // const newValue = event.target.value.replace(/\s+/g, ''); 
        const newValue = event.target.value;
        setName(newValue);
        setError('');


        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.userName = newValue;
                profile.userPosition = "CEO";
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                userName: newValue,
                userPosition: "CEO"
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }

    };

    useEffect(() => {
        if (name == "") {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }, [name])

    const [error, setError] = useState('')





    const { trackEvent } = useMixpanel();


    const { data: businessProfiles, isLoading: isLoadingProfiles, refetch } = useFetchBusinessProfilesQuery();
    const [updateProfile, { isLoading: updating, error: updateError }] = useUpdateBusinessProfileMutation();

    const handleUpdateProfile = async () => {
        try {
            const profileId = businessProfiles?.data?.id;
            console.log(businessProfiles?.data?.id)
            const data = {
                // ...businessProfiles?.data,
                companyName: name,
            }
            if (profileId) {
                await updateProfile({ id: profileId, data }).unwrap();
                console.log('Profile updated successfully');
            } else {
                console.error('Profile ID not found');
            }
        } catch (err) {
            console.error('Error updating profile:', err);
        }
    };

    return (
        <div className='page page--without-button'>


                <div className="namePage-top">
                    <CustomTitle style={{ marginBottom: "24rem" }} title="What’s your company name?" />
                    <div className={`input ${error ? 'error' : ''}`}
                        style={{ paddingLeft: "16rem", paddingRight: "16rem", alignItems: 'flex-start' }}
                    >

                        <input
                            type="text" placeholder='Enter your company name'
                            value={name}
                            onChange={handleInputChange}
                        />
                        <div className={`input-border ${name === '' ? 'empty' : ''}`}></div>
                        <span style={{ left: '16rem' }}>Company name</span>

                        {error && (
                            <div className="error-text" >
                                {error}
                            </div>
                        )}
                    </div>
                </div>





            {/* <WithWizard
                render={({ next, previous, step, steps }) => (
               
                )}
            /> */}

            <MyButton
                onClick={() => {
                    if (isButtonDisabled) {
                        setError('Please enter your company name')
                        return
                    }
                    if (save) {
                        dispatch(setBrandName(name));
                        dispatch(setAdjustedIndustry(true));
                        history.push('/product-check')
                    } else {
                        handleUpdateProfile()
                        trackEvent('onboarding_product_check', { company_determination: 'MANUAL' }, true);
                        history.push('/ai-loader1')
                    }
                }}
                // className={isButtonDisabled ? 'disable' : ''}
                buttonIcon={'no'}
                buttonText={`${save ? 'Save' : 'Continue'}`}
            >

            </MyButton >

        </div>
    );
}

export default CompanyName;
