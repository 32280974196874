import { useCallback } from "react";
import mixpanel from "mixpanel-browser";
import { useFetchBusinessProfilesQuery, useGetInfoQuery } from "../api/dataApi";

const useMixpanel = () => {
  const { data: userInfo } = useGetInfoQuery();
  const { data: businessProfiles, isLoading: isLoadingProfiles } =
    useFetchBusinessProfilesQuery();

  const direction = businessProfiles?.data?.direction;

  const trackEvent = (eventName, data, isProduct) => {
    mixpanel.track(eventName, {
      ...(userInfo?.data?.id && { user_id: userInfo?.data?.id }),
      ...(localStorage.getItem("signupMethod") && {
        web_entrance:
          localStorage.getItem("signupMethod") === "Login" ? "login" : "signup",
      }),
      ...(localStorage.getItem("web_booster_pay")
        ? { web_level: "GROWTH" }
        : { web_level: "TRIAL" }),
      web_plan__name: localStorage.getItem("plan"),
      ...(isProduct && { product_type: direction?.toLowerCase() }),
      ...data,
    });
  };

  const trackP1Event = (eventName, data) => {
    mixpanel.track(eventName, {
      ...data,
      ...(localStorage.getItem("userId") && { user_id: localStorage.getItem("userId") }),
      ...(localStorage.getItem("utmCampaign") && { web_utm_campaign: localStorage.getItem("utmCampaign") }),
      ...(localStorage.getItem("utmSource") && { web_utm_source: localStorage.getItem("utmSource") }),
      ...(localStorage.getItem("utmMedium") && { web_utm_medium: localStorage.getItem("utmMedium") }),
    });
  };

  return { trackP1Event, trackEvent };
};

export default useMixpanel;
